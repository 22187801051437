<template>
  <div>
    <b-card title="Protection Policy Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 220px">
                  Protection Policy
                </th>
                <td>
                  {{ groupName }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card
      :title="`Jobs (${orderedJobs.length})`"
    >
      <JobTable
        :jobs="orderedJobs"
        :time-range="timeRange"
        :timezone="asup.timezone"
        type="protection-policy"
      />
    </b-card>

    <b-card title="Clients">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Client</th>
            <th>Backup jobs</th>
            <th>Replication jobs</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(c, index) in clients"
            :key="index"
          >
            <th>
              <b-link :to="{ name: 'ppdm-detail.client-detail', params: { clientName: c.name } }">
                {{ c.name }}
              </b-link>
            </th>
            <td>{{ c.backupJobCount }} <span v-if="c.backupJobFailedCount">({{ c.backupJobFailedCount }} failed)</span></td>
            <td>{{ c.replicationJobCount }} <span v-if="c.replicationJobFailedCount">({{ c.replicationJobFailedCount }} failed)</span></td>
          </tr>
        </tbody>
      </table>
    </b-card>

  </div>
</template>
<!-- eslint-disable no-param-reassign -->
<script>

import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BLink,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import JobTable from './components/JobTable.vue'

import moment from '@/libs/moment'
import PpdmServivce from '@/service/ppdm.service'

export default {
  components: {
    BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, BLink, DateRangePicker, JobTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      client: {},
      timeRange: {
        range: 'last7Days',
      },
      onlyErrors: false,
      jobs: [],
    }
  },
  computed: {
    groupName() {
      return this.$route.params.name
    },
    orderedJobs() {
      return this.jobs.concat().sort((a, b) => (a.start > b.start ? -1 : 1))
    },
    clients() {
      return this.jobs.reduce((p, c) => {
        let client = p.find(x => x.name === c.clientName)
        if (!client) {
          client = {
            name: c.clientName,
            backupJobCount: 0,
            backupJobFailedCount: 0,
            replicationJobCount: 0,
            replicationJobFailedCount: 0,
          }

          p.push(client)
        }

        if (c.category === 0) {
          // eslint-disable-next-line no-param-reassign
          client.backupJobCount += 1
          if (client.status === 30) {
            client.backupJobFailedCount += 1
          }
        } else if (c.category === 1) {
          // eslint-disable-next-line no-param-reassign
          client.replicationJobCount += 1
          if (client.status === 30) {
            client.replicationJobFailedCount += 1
          }
        }

        return p
      }, [])
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadData)
    this.$watch('onlyErrors', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      PpdmServivce.getJobListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        protectionPolicy: this.groupName,
      }, { disableTenantFilter: true })
        .then(result => {
          // automatically show details for failed jobs
          for (let i = 0; i < result.items.length; i += 1) {
            if (result.items[i].status === 30) {
            // eslint-disable-next-line no-param-reassign
              result.items[i].showDetails = true
            }
          }

          this.jobs = result.items
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
